<template>
  <ClientOnly>
    <div v-if="release" class="hidden w-full sm:block">
      <div
        class="relative w-full flex flex-col items-center max-h-[400px] bg-black bg-center bg-no-repeat bg-cover overflow-hidden"
      >
        <video
          class="w-full object-cover inset-0"
          autoplay
          muted
          loop
          preload
          playsinline
          :src="release.posterStreamUrl"
          :poster="release.posterUrl"
        />

        <div
          class="absolute right-0 bottom-0 left-0 py-2 px-3 bg-black/25 backdrop-blur-xl saturate-150 flex flex-col items-center"
        >
          <div
            class="w-full max-w-7xl flex flex-row justify-between items-center"
          >
            <div class="">
              <p class="text-2xl font-semibold leading-tight">
                {{ release.title }}
              </p>
              <p class="text-lg">
                <span
                  v-for="(actor, i) in release.actors"
                  :key="`feature-header-actor-${i}`"
                >
                  <a :href="`/models/${actor.cached_slug}`">
                    {{ actor.name }}
                  </a>
                  <span v-if="i < release.actors.length - 1">,&nbsp;</span>
                </span>
              </p>
            </div>
            <div class="flex flex-row gap-2">
              <div class="flex flex-col items-end bg-black/90 py-2 px-3">
                <strong
                  class="uppercase font-semibold text-blue-200 tracking-wide"
                >
                  Score
                </strong>
                <span class="text-lg font-black">
                  {{ release.ratingsDecimal.toFixed(2) }}
                </span>
              </div>
              <div class="flex flex-col items-end bg-black/90 py-2 px-3">
                <strong
                  class="uppercase font-semibold text-blue-200 tracking-wide"
                >
                  Rank
                </strong>
                <span class="text-lg font-black">
                  {{ release.rankSite }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <Divider />
      </div>

      <div class="w-full max-w-7xl mx-auto flex flex-col items-center p-6">
        <h3
          class="text-2xl text-[#29f863] text-center"
          v-html="currentSite.siteHeader"
        />
        <p
          class="max-w-3xl text-xl text-center"
          v-html="currentSite.description"
        />
      </div>
    </div>
  </ClientOnly>
</template>

<script setup>
  const currentSite = inject("currentSite")

  const props = defineProps({
    releases: Array,
  })

  const release = ref(null)

  onMounted(() => {
    release.value =
      props.releases[Math.floor(Math.random() * props.releases.length)]
  })
</script>
