<template>
  <LayoutClassicTour>
    <TourClassicFeaturedVideosHeader
      v-if="
        featuredReleasesStatus === 'success' &&
        currentSite.showFeaturedVideos &&
        pageNumber == 1
      "
      :releases="featuredReleases.items"
    />

    <SectionContainer
      v-if="
        topRatedReleasesStatus === 'success' &&
        topRatedReleases &&
        pageNumber == 1
      "
      :title="`Top Rated ${currentSite.name} Movies`"
      class="mt-1"
    >
      <div class="card-grid">
        <ReleaseCard
          v-for="(release, i) in topRatedReleases.items"
          :key="`top-rated-${i}`"
          :release="release"
          :show-rank="true"
          :show-rating="true"
          :vertical="useVerticalCards"
        />
      </div>
    </SectionContainer>

    <SectionContainer
      v-if="
        latestReleasesStatus === 'success' &&
        latestReleases &&
        !pageIsBeyondVideos
      "
      :title="`Latest ${currentSite.name} Movies`"
    >
      <div class="card-grid">
        <ReleaseCard
          v-for="(release, i) in latestReleases.items"
          :key="`latest-${i}`"
          :release="release"
          :show-rating="false"
          :vertical="useVerticalCards"
        />
      </div>
    </SectionContainer>

    <SectionContainer
      v-if="tourImagesStatus === 'success' && tourImages && pageIsBeyondVideos"
    >
      <div class="card-grid">
        <TourClassicImageAssetCard
          v-for="(image, i) in tourImages.items"
          :key="`tour-image-${i}`"
          :image="image"
        />
      </div>
    </SectionContainer>

    <Pagination
      :current-page="pageNumber"
      :total-pages="totalPageCount"
      resource=""
    />
  </LayoutClassicTour>
</template>

<script setup>
  const currentSite = inject("currentSite")
  const route = useRoute()
  const { isMobile } = useResponsive()
  const pageNumber = parseInt(route.query.page) || 1
  const perPage = isMobile.value ? 6 : 12
  const { useVerticalCards } = useSiteSettings(currentSite.value)

  useSiteSeo(currentSite, route, {
    title: () => {
      if (route.query.page > 1) {
        return `%siteDefaultTitle %separator Page: ${route.query.page}`
      } else {
        return "%siteDefaultTitle"
      }
    },
    titleTemplate: "%s %separator %siteNameWithTld",
    meta: [
      {
        name: "description",
        content: () => {
          if (route.query.page > 1) {
            return `%siteDefaultDescription %separator Page: ${route.query.page}`
          } else {
            return `%siteDefaultDescription`
          }
        },
      },
    ],
  })

  const [
    { data: topRatedReleases, status: topRatedReleasesStatus },
    { data: latestReleases, status: latestReleasesStatus },
    { data: featuredReleases, status: featuredReleasesStatus },
  ] = await Promise.all([
    useApi(`/api/releases?sort=top_rated&per_page=${perPage}`),
    useApi(`/api/releases?sort=latest&page=${pageNumber}&per_page=${perPage}`),
    useApi("/api/releases?sort=featured&per_page=6"),
  ])

  const videoPageCount = latestReleases.value?.pagination?.totalPages || 0

  const pageIsBeyondVideos = pageNumber > 1 && videoPageCount < pageNumber
  const adjustedTourImagePageNumber = pageIsBeyondVideos
    ? pageNumber - videoPageCount
    : pageNumber

  const { data: tourImages, status: tourImagesStatus } = await useApi(
    `/api/images?page=${adjustedTourImagePageNumber}&per_page=${perPage}`,
  )

  const totalPageCount =
    videoPageCount + tourImages.value?.pagination?.totalPages || 0
</script>
